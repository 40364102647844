import React from "react";

function Footer() {
  return (
    <footer className="mt-8 text-center text-gray-300">
      <p>&copy; 2024 🤍 Avav Abdillah Sam</p>
    </footer>
  );
}

export default Footer;
